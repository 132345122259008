import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import { Button } from "../../Core";
import { navigate } from "gatsby";
import CheckAvailability from "../CheckAvailability/CheckAvailability";

const HeroWhy = (props) => {
    return (
        <div className="dark-bg hero-section">
            <Container>
                <Row>
                    <Col sm={12} className="mt-80">
                        <h1>Ultra-Fast<br />Ultra-affordable<br />Ultra-You</h1>
                    </Col>
                    <Col sm={12} md={8} lg={6} className="mb-80">
                        <CheckAvailability minimized={true}/>
                    </Col>
                </Row>
            </Container>
        </div>
    )
};

export default HeroWhy;
